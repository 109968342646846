import React, { FunctionComponent } from "react"
import { navigate } from "gatsby"
import { FluidObject } from "gatsby-image"
import { LearnListItem } from "../../learn-types"
import { CardLearn } from "../../../../components/card/card-learn"
import styles from "./learn-list.module.scss"

export interface LearnListProps {
  items: LearnListItem[]
}

export const LearnList: FunctionComponent<LearnListProps> = ({ items }) => (
  <div className={styles.list}>
    {items.map((item) => (
      <CardLearn
        {...item}
        imageFluid={item.imageFluid as FluidObject}
        size="fluid"
        className={styles.card}
        key={item.link}
      />
    ))}
  </div>
)
