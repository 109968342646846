import { FluidObject } from "gatsby-image"
import { LearnPageQuery } from "../../../../graphql/types"
import { LearnListItem } from "../learn-types"

export const mapSourceLearnListItemsToView = (
  queryResult: LearnPageQuery
): LearnListItem[] => {
  return queryResult.allContentfulLearnIndexCard.nodes.reduce<LearnListItem[]>(
    (list, item) => {
      if (!item.image || !item.childContentfulLearnIndexCardBodyTextNode) {
        return list
      }

      list.push({
        body: item.childContentfulLearnIndexCardBodyTextNode!.body!,
        image: item.image?.fluid!.src,
        imageFluid: item.image?.fluid as FluidObject,
        link: item.link!,
        title: item.title!
      })

      return list
    },
    []
  )
}
