import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { LearnHeader } from "../learn-header/learn-header"
import { LearnList } from "../learn-list/learn-list"
import { LearnListItem } from "../../learn-types"

export interface LearnPageProps {
  pageHeader: IndexPageHeader
  listItems: LearnListItem[]
}

export const LearnPage: FunctionComponent<LearnPageProps> = ({
  pageHeader,
  listItems
}) => {
  return (
    <Fragment>
      <LearnHeader header={pageHeader} />
      <LearnList items={listItems} />
    </Fragment>
  )
}
