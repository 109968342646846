import React, { FunctionComponent } from "react"
import Image, { FluidObject } from "gatsby-image"
import { IndexPageHeader } from "../../../../types/page"
import styles from "./learn-header.module.scss"

export interface LearnHeaderProps {
  header: IndexPageHeader
}

export const LearnHeader: FunctionComponent<LearnHeaderProps> = ({
  header
}) => (
  <header className={styles.header}>
    <div className={styles.textContent}>
      <h1 className={styles.title}>{header.title}</h1>
      <p className={styles.subtitle}>{header.subtitle}</p>
    </div>
    <div className={styles.image}>
      <Image fluid={header.imageFluid as FluidObject} />
    </div>
  </header>
)
