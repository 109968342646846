import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { useIndexPageData } from "../../hooks/use-index-page-data"
import { LearnPageQuery } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { LearnPage } from "../../features/learn/components/learn-page/learn-page"
import { mapSourceLearnListItemsToView } from "../../features/learn/mappers/learn-list-items-mapper"
import { Page } from "../../components/page/page"
import { PageBreadcrumbs } from "../../core/breadcrumbs"
import { PageOpenGraphImage } from "@core/constants"

export const Learn: FunctionComponent<PageProps<LearnPageQuery>> = ({
  data,
  ...props
}) => {
  const { header, metadata } = useIndexPageData("learn")
  const items = mapSourceLearnListItemsToView(data!)

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Learn
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Learn
      }}
    >
      <LearnPage pageHeader={header} listItems={items} />
    </Page>
  )
}

export default Learn

export const query = graphql`
  query LearnPage {
    allContentfulLearnIndexCard(sort: { fields: order, order: ASC }) {
      nodes {
        ...LearnListItem
      }
    }
  }
`
